fury-report-editor,
fury-waste-order-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  fury-page-header-buttons {
    flex: initial;
  }

  .mat-form-field {
    width: 100%;

    .mat-icon.small-icon {
      width: 13px;
      height: 13px;
      min-width: 13px;
      min-height: 13px;
      font-size: 13px;
      vertical-align: bottom;
      margin-right: 2px;
      margin-left: -10px;
    }
  }

  .overflow-container {
    height: 100%;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
  }

  .text-padding {
    padding: 24px;
  }

  p {
    margin: 0;
  }

  .progress-bar {
    margin-top: 8px;
  }
}

.report-panel-header {
  padding: 10px 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;

    .has-attachments {
      vertical-align: middle;
      margin-right: 8px;
      font-size: 18px;
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px
    }

    .anonymized {
      background: #1976d2;
      color: #fff;
      margin: 0 5px;
      padding: 0 5px;
      display: inline-block;
      line-height: 1.4;

      mat-icon {
        vertical-align: middle;
      }
    }
  }
}

.report-panel-body {
  padding: 10px 15px;

  mat-label + .fake-mat-form-field-content {
    padding-top: 24px !important;
  }

  .mat-checkbox + .mat-checkbox {
    margin-top: -4px;
  }

  .quill-container {
    padding-bottom: 1.34375em;
    min-height: 150px;
  }

  .h1 {
    font-size: 32px;
    line-height: 1.3;
    font-weight: 500;
    margin-bottom: 0;
  }

  h3 {
    padding: 16px 16px 0;

    &.notes-header {
      &:nth-child(2) {
        margin-top: 0;
      }

      font-size: 14px;
      font-weight: 500;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      margin: 10px 0 6px 0;
      padding: 0 0 10px 0;
      line-height: 15px;
      border-bottom-color: rgba(0, 0, 0, 0.1);
    }
  }

  .report-panel-info {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: 15px 20px;
    margin: 0 0 16px 0;

    &.no-border {
      border: 0;
      padding: 0;
      margin: 0;
    }

    &.no-space-top {
      margin-top: 0;
    }

    dl {
      margin: 0;
      display: flex;
      flex-direction: row;

      @media (max-width: 480px) {
        flex-direction: column;

      }

      dd {
        margin: 0 6px 6px 0;
        float: left;
        font-weight: bold;
        width: 200px;
        min-width: 200px;

        @media (max-width: 480px) {
          width: 100%;
          min-width: 100%;
          display: block;
        }
      }

      dt {
        min-height: 20px;
      }

      .status-color {
        display: inline-block;
        padding: 2px 7px;
        margin: -2px -7px;
      }
    }
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 10px 0 16px 0;
    padding: 0 0 10px 0;
    line-height: 15px;

    .mat-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
      vertical-align: bottom;
      min-width: 16px;
      min-height: 16px;
    }
  }

  hr {
    width: 100%;
    border: 0;
    border-top-width: 1px;
    border-top-style: solid;
    margin: 16px 0;
    padding: 0;
  }

  .mat-raised-button + hr {
    margin-top: 0;
  }

  .mat-checkbox,
  .mat-raised-button {
    margin-bottom: 16px;
  }

  .media {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    row-gap: 16px;
    margin-bottom: 16px;

    & > div {
      overflow: hidden;
      aspect-ratio: 2;
      border-width: 2px;
      border-style: solid;
      position: relative;

      & > .mat-icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
      }

      & > div {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;

        & > .mat-icon {
          position: relative;
          top: initial;
          right: initial;
        }
      }
    }

    img,
    video {
      max-width: 100%;
    }
  }

  .documents {
    margin-bottom: 16px;

    & > div {
      cursor: pointer;
    }
  }

  fury-alert-box.border {
    font-weight: bold;
  }

  .blue-border {
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
    padding: 10px 15px;
    margin-bottom: 16px;
    margin-top: 34px;

    h2 {
      border-bottom: 0;
      margin-bottom: 0;
    }

    > div {
      .mat-icon {
        margin: 0 10px 0 0;
      }
    }
  }

  .mat-checkbox-horizontal {
    .mat-checkbox {
      margin: 0 16px 0 0;
    }
  }

  .button-container button + button {
    margin-left: 5px;
  }

  .two-buttons-in-row .mat-raised-button {
    flex: 1 1 50%;
  }

  .response {
    margin-bottom: 10px;

    .content {
      margin-top: 5px;
    }
  }

  .fake-live-mat-checkbox {
    .mat-checkbox-label {
      color: initial;
    }
  }

  .suspect-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    &.space {
      margin-top: 9px;
    }

    & > div {
      flex-direction: row;
      display: flex;
      align-items: center;
      border-width: 2px;
      border-style: solid;
      border-radius: 3px;
      padding: 8px 10px;

      & > .mat-icon {
        align-self: flex-start;
      }
    }
  }

  h2 + .note-container {
    margin-top: -16px;
  }
}

.note-container {
  &:last-child {
    margin-bottom: 16px;
  }

  & + .note-container {
    margin-top: 6px;
    padding-top: 6px;
    border-top-width: 1px;
    border-top-style: solid;
  }

  & + .mat-form-field {
    margin-top: 16px;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-style: italic;
    display: flex;
    justify-content: space-between;

    .mat-icon-button {
      width: 16px;
      height: 16px;
      font-size: 16px;
      line-height: 16px;
      margin-left: 6px;

      .mat-icon {
        line-height: 16px;
        font-size: 16px;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
      }
    }
  }
}

.mat-option-multiline {
  line-height: initial !important;
}

.report-panel-footer {
  padding: 10px 15px;
  border-top-width: 1px;
  border-top-style: solid;

  .mat-raised-button + .mat-raised-button {
    margin-left: 5px;
  }
}

@media screen and (max-width: 959px) {
  .report-panel {
    width: 80vw;
    min-width: 80vw;
    max-width: 80vw;
  }
}

@media (max-width: 640px) {
  .weather-widget {
    flex-wrap: wrap;

    div {
      width: 50%;
    }
  }

}

.hidden {
  display: none;
}

.suspect-recent-reports {
  table-layout: fixed;
  overflow: hidden;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
